import { LitElement, html } from 'lit-element';
import { specialsReplace } from '../utils/html';
import { getFileType } from '../utils/url';

class HomeBanner extends LitElement {

    constructor () {
        super();

        const hasUser = !!window.constants.userId;
        const copyType = hasUser ? 'authenticated' : 'public';
        this.isVisible = (window.copy.getClientInstance( `home_banner_${copyType}_visibility` ) || '').toLowerCase() === 'on';
        this.bannerSrc = window.copy.getClientInstance( `home_banner_${copyType}_media_url` );
        this.bannerTitle = specialsReplace( window.copy.getClientInstance( `home_banner_${copyType}_title` ) );
        this.bannerDescription = specialsReplace( window.copy.getClientInstance( `home_banner_${copyType}_description` ) || '' );
        this.bannerCTA1Label = specialsReplace( window.copy.getClientInstance( `home_banner_${copyType}_cta1_label` ) );
        this.bannerCTA1Url = window.copy.getClientInstance( `home_banner_${copyType}_cta1_url` );
        this.bannerCTA1TrackingCode = window.copy.getClientInstance( `home_banner_${copyType}_cta1_tracking_code` );
        this.bannerCTA2Label = specialsReplace( window.copy.getClientInstance( `home_banner_${copyType}_cta2_label` ) );
        this.bannerCTA2Url = window.copy.getClientInstance( `home_banner_${copyType}_cta2_url` );
        this.bannerCTA2TrackingCode = window.copy.getClientInstance( `home_banner_${copyType}_cta2_tracking_code` );
        this.bannerType = getFileType( this.bannerSrc );
    }

    createRenderRoot () {
        /**
        * Render template without shadow DOM. Note that shadow DOM features like
        * encapsulated CSS and slots are unavailable.
        */
        return this;
    }

    handlePlay(evt) {
      this.renderRoot.querySelector('video').play()
        .catch( _ => {} );
    }

    handleOnPlay(evt) {
      this.renderRoot.querySelector('.controls').classList.add('playing');
      this.renderRoot.querySelector('.play').classList.add('hidden');
      this.renderRoot.querySelector('.pause').classList.remove('hidden');
    }

    handlePause(evt) {
      this.renderRoot.querySelector('video').pause();
    }

    handleOnPause(evt) {
      this.renderRoot.querySelector('.controls').classList.remove('playing');
      this.renderRoot.querySelector('.play').classList.remove('hidden');
      this.renderRoot.querySelector('.pause').classList.add('hidden');
    }

    render() {

      if (!this.isVisible || !!!this.bannerSrc) {
        return ``;
      }

      const description = this.bannerDescription ? html`<p class="description">${this.bannerDescription}</p>` : '';

      const makeCTA = ( url, label, trackingCode, secondary = false ) => {
        let u = (url || '').trim();
        if (!u || !label) {
          return '';
        }
        let target = '';
        if (u.startsWith('*')) {
          target='_blank';
          u = u.substring(1,9999);
        }
        let tracking = trackingCode ? `gtag('event','cta',{'target':'${trackingCode}'})` : '';
        return html`<a href="${u}" target="${target}" onClick="${tracking}" class="button ${secondary?'secondary':''}">${label}</a>`
      }

      const cta1 = makeCTA( this.bannerCTA1Url , this.bannerCTA1Label, this.bannerCTA1TrackingCode );
      const cta2 = makeCTA( this.bannerCTA2Url , this.bannerCTA2Label, this.bannerCTA2TrackingCode, !!cta1 );
      
      const media = this.bannerType === 'video' ?
        html`<video class="media" playsinline autoplay muted loop role="video" src="${ this.bannerSrc }" @play="${this.handleOnPlay}" @pause="${this.handleOnPause}"></video>` :
        html`<img class="media" role="image" src="${ this.bannerSrc }" />`;
      
      const mediaControls = this.bannerType === 'video' ? html`
        <button class="button playback play" aria-disabled="false" @click="${this.handlePlay}">
          <span aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 72 72" height="24px" width="24px">
              <g clip-path="url(#Play__Filled_svg__a)">
                <path fill="white" d="M19.8 57.06 55.8 36l-36-21.06v42.12Z"></path>
              </g>
              <defs>
                <clipPath id="Play__Filled_svg__a">
                  <path fill="white" d="M0 0h72v72H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </span>
        </button>

        <button class="button playback pause" aria-disabled="false" @click="${this.handlePause}">
          <span aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 72 72" height="24px" width="24px">
              <g clip-path="url(#Pause_svg__a)">
                <path fill="white" d="M23.4 14.4h7.2v43.2h-7.2V14.4Zm18 0h7.2v43.2h-7.2V14.4Z"></path>
              </g>
              <defs>
                <clipPath id="Pause_svg__a">
                    <path fill="white" d="M0 0h72v72H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </span>
        </button>` : '';


      return html`
          <div class="HomeBanner ${this.bannerType}">
            <div class="container">
              ${media}
              <div class="controls">
                <h1 class="title">${this.bannerTitle}</h1>
                ${description}
                <div class="actions">
                  ${cta1}
                  ${cta2}
                  ${mediaControls}
                </div>
              </div>
              
            </div>
          </div>
      `;
    }

}

customElements.define( 'home-banner', HomeBanner );
