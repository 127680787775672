export const getFileType = (url) => {
  const ext = (url.split('?')[0].split('.').pop() ?? '').toLowerCase();

  if (['mp4'].includes(ext))
    return 'video';

  if (['jpg', 'jpeg', 'png', 'gif'].includes(ext))
    return 'image';

  return '';
}